
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contentBlocks () {
      return this.post.acf?.home_config.flexible_content;
    },
  },
};
